export const MAX_ZOOM = 8;
export const style = {
  "version": 8,
  "sources": {
    "country-boundaries": {
      "type": "vector",
      "url": "mapbox://mapbox.country-boundaries-v1?optimize=true"
    },
    "postgis-tiles": {
      "type": "vector",
      "tiles": [
        `${process.env.REACT_APP_DENSITY_API}/{z}/{x}/{y}.mvt`
      ]
    }
  },
  "layers": [
    {
      "id": "country-boundaries-layer",
      "source": "country-boundaries",
      "source-layer": "country_boundaries",
      "type": "fill",
      "maxzoom": MAX_ZOOM,
      "paint": {
        "fill-color": "#272727",
        "fill-outline-color": "#878787"
      }
    },
    {
      "id": "postgis-tiles-layer",
      "source": "postgis-tiles",
      "source-layer": "default",
      "type": "fill",
      "maxzoom": MAX_ZOOM,
      "paint": {
        "fill-color": [
          "step",
          // "interpolate",
          // ["linear"],
          ["get", "density_tier"],
          // 1
          "#58b453",
          2,
          "#97d265",
          3,
          "#c4e687",
          4,
          "#ecf7ad",
          5,
          "#ffedab",
          6,
          "#fec981",
          7,
          "#f99e59",
          8,
          "#e85b3a",
          9,
          "#d7191c"
        ]
        // "fill-color": [
        //   "step",
        //   // "interpolate",
        //   // ["linear"],
        //   ["get", "count"],
        //   // 1,
        //   "transparent",
        //   4,
        //   "#58b453",
        //   6,
        //   "#97d265",
        //   11,
        //   "#c4e687",
        //   21,
        //   "#ecf7ad",
        //   36,
        //   "#ffedab",
        //   51,
        //   "#fec981",
        //   91,
        //   "#f99e59",
        //   400,
        //   "#e85b3a",
        //   600,
        //   "#d7191c"
        // ]
      }
    }
  ]
}
